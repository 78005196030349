<template>
<div>
  <base-page :title="$t('personalInfo.title')" @functionMethod="functionMethod">
    <detail-list :formData="formData" :config="config">
      <!-- 所属企业 -->
      <template v-slot:company="{ data }">
        {{ data.company.name }}
      </template>
      <!-- 角色 -->
      <template v-slot:roles="{ data }">
        {{
          data.roles && data.roles.length
            ? data.roles.map((item) => item.name).join(',')
            : ''
        }}
      </template>
      <!-- 账号类型 -->
      <template v-slot:roleType="{ data }">
        {{ roleTypeObj[data.roleType] }}
      </template>
    </detail-list>
    <!-- <detail v-if="showDetail" :tableRow="formData"  @closePage="closePage" /> -->
  </base-page>
  <!-- 绑定手机号 -->
<el-dialog
      :title="$t('adminAccount.bindMobile')"
      ref="baseDialog"
      :visible.sync="bindMobileShow"
      v-if="bindMobileShow" 
      class="passBox"
      width="650px"
      :modal="false"
      :close-on-click-modal="false"
      :modal-append-to-body='false'
    >
  <el-form>
    <template v-if="choosed==='one'">
      <el-row class="fromBox">
        <el-col :span="6" class="colBox" :gutter="2">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('adminAccount.mobile')" placement="top-start"><span>{{$t('adminAccount.mobile')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="mobile" :readonly="status ? 'readonly' : false " :placeholder="$t('base.pleaseEnter')" maxlength="11"></el-input>
        </el-col>
      </el-row>
      <el-row class="fromBox">
        <el-col :span="6" class="colBox">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('adminAccount.mobile')" placement="top-start"><span>{{$t('adminAccount.mobile')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <div style="position: relative">
            <el-input v-model="authCode"  :placeholder="$t('adminAccount.codeText')" maxlength="6" >
            <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
            </el-button>
            <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
          </el-input>
          <div class="el-form-item__error" v-show="codeErrshow">{{codeErrText}}</div>
        </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="footBox">
          <el-button @click="closePage">{{ $t('base.cancel') }}</el-button>
          <el-button type="primary" @click="bindMobile">
            <!-- lang:确定 -->
            {{ $t('base.confirm') }}
          </el-button>
          </div>
        </el-col>
      </el-row>
    </template>
  </el-form>
 </el-dialog>

   <el-dialog
      :title="$t('adminAccount.modifyPass')"
      ref="baseDialog"
      :visible.sync="showDetail"
      v-if="showDetail" 
      class="passBox"
      width="650px"
      :modal="false"
      :close-on-click-modal="false"
      :modal-append-to-body='false'
    >
  <el-form>
    <template v-if="choosed==='one'">
      <el-row class="fromBox">
        <el-col :span="6" class="colBox" :gutter="2">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('adminAccount.mobile')" placement="top-start"><span>{{$t('adminAccount.mobile')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="mobile" :readonly="status ? 'readonly' : false " :placeholder="$t('base.pleaseEnter')" maxlength="11"></el-input>
        </el-col>
      </el-row>
      <el-row class="fromBox">
        <el-col :span="6" class="colBox">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('adminAccount.mobile')" placement="top-start"><span>{{$t('adminAccount.mobile')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <div style="position: relative">
            <el-input v-model="authCode"  :placeholder="$t('adminAccount.codeText')" maxlength="6" >
            <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
            </el-button>
            <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
          </el-input>
          <div class="el-form-item__error" v-show="codeErrshow">{{codeErrText}}</div>
        </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="footBox">
          <template>
          <!-- <el-button >{{ $t('base.cancel') }}</el-button> -->
          <el-button type="primary" @click="nextStep">
            <!-- lang:确定 -->
            {{ $t('adminAccount.nextStep') }}
          </el-button>
          </template>
          </div>
        </el-col>
      </el-row>
    </template>
    <template v-if="choosed==='two'">
      <el-row class="fromBox">
        <el-col :span="6" class="colBox" :gutter="2">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('personalInfo.oldPassword')" placement="top-start"><span>{{$t('personalInfo.oldPassword')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="oldPassword" @change="checkPassword" type="password" :placeholder="$t('base.validatePassword')"></el-input>
        </el-col>
      </el-row>
      <el-row class="fromBox">
        <el-col :span="6" class="colBox" :gutter="2">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('personalInfo.password')" placement="top-start"><span>{{$t('personalInfo.password')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="password" @change="checkPassword" type="password" :placeholder="$t('base.validatePassword')"></el-input>
        </el-col>
      </el-row>
      <el-row class="fromBox">
        <el-col :span="6" class="colBox" :gutter="2">
          <div class="leftBox">
            <span class="starBox">*</span><el-tooltip class="item" effect="dark" :content="$t('personalInfo.confirmPassword')" placement="top-start"><span>{{$t('personalInfo.confirmPassword')}}：</span></el-tooltip>
          </div>
        </el-col>
        <el-col :span="18">
          <el-input v-model="confirmPassword" @change="checkPassword" type="password" :placeholder="$t('base.validatePassword')"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="footBox">
          <el-button @click="closePage">{{ $t('base.cancel') }}</el-button>
          <el-button type="primary" @click="confirm">
            <!-- lang:确定 -->
            {{ $t('base.confirm') }}
          </el-button>
          </div>
        </el-col>
      </el-row>
    </template> 
  </el-form>
 </el-dialog>
</div>
</template>

<script>
// import Detail from './detail'
import { resetRouter } from '@/router'

export default {
  // components: {
  //   Detail,
  // },
  data() {
    return {
      formData: null,
      mobile:'',
      authCode:'',
      oldPassword:'',
      password:'',
      confirmPassword:'',
      authCodeSeqNo:'',
      count: '', // 验证码倒计时
      timer:null ,
      codeShow: true,
      codeErrText:'',
      choosed:'one',
      isSend: true,
      status: false,
      codeErrshow: false,
      roleTypeObj: this.$util.listToObj(this.$dict.roleType()),
      config: [
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
          span: 12,
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
          span: 12,
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'loginName',
          span: 12,
        },
        // lang:账号类型
        {
          label: this.$t('adminAccount.type'),
          prop: 'roleType',
          span: 12,
        },
        // lang:所属企业
        {
          label: this.$t('adminAccount.company'),
          prop: 'company',
          span: 12,
        },
        // lang:角色
        {
          label: this.$t('adminAccount.role'),
          prop: 'roles',
          span: 12,
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickname',
          span: 12,
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          callback: (row) => {
            return this.$util.format(row.createTime, 'yyyy-MM-dd hh:mm:ss')
          },
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
          span: 12,
        },
      ],
      showDetail: false,
      bindMobileShow:false
    }
  },
  mounted() {
    this.operatorFindMe()
  },
  methods: {
    // 刷新页面
    closePage() {
      this.showDetail = false
    },
    // 查询个人信息
    operatorFindMe() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorFindMe,
      }).then((data) => {
        console.log(data)
        this.formData = data
        this.mobile = data.mobile     
      })
    },
    // 权限按钮点击回掉
    functionMethod() {
      if(this.timer) {
        clearInterval(this.timer)
        this.timer = null
        this.codeShow = true
      }
      this.choosed = 'one'
      this.authCode = ''
      if(this.formData.mobile){
        this.mobile = this.mobileHide(this.formData.mobile)
        this.status = true
        this.showDetail = true
      }else{
        this.mobile = ""
        this.status = false
        this.bindMobileShow = true
      }
    },
    bindMobile() {
      if(!this.timer) {
        clearInterval(this.timer)
        this.timer = null
        this.codeShow = true
      }
      if(!this.mobile){
        this.$element.showMsg(this.$t('adminAccount.mobileTxt'), 'warning')
        return
      }
      // const reg = /^[1][3-9][0-9]{9}$/
      // if(!reg.test(this.mobile)){
      //   this.$element.showMsg(this.$t('adminAccount.mobileErr'), 'warning')
      //   return
      // }
      if(!this.authCode){
        this.$element.showMsg(this.$t('adminAccount.codeText'), 'warning')
        return
      }
        this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorbindMobile,
        data: {
          operatorId:this.formData.id,
          mobile:this.mobile,
          seqNo: this.authCodeSeqNo,
          code:this.authCode
        }
      }).then(() => {
        this.$set(this.formData,'mobile',this.mobile)
        this.bindMobileShow = false
        this.$element.showMsg(this.$t('adminAccount.bindMobileSec'))
        if(this.timer) {
          clearInterval(this.timer)
          this.timer = null
          this.codeShow = true
        }
      })
   
      this.choosed = 'two'
      this.oldPassword = ''
      this.password = ''
      this.confirmPassword = ''
    },
    nextStep() {
      if(!this.mobile){
        this.$element.showMsg(this.$t('adminAccount.mobileTxt'), 'warning')
        return
      }
      if(!this.authCode){
        this.$element.showMsg(this.$t('adminAccount.codeText'), 'warning')
        return
      }
      if(this.formData.mobile){
        this.mobile = this.formData.mobile
      }
      this.choosed = 'two'
      this.oldPassword = ''
      this.password = ''
      this.confirmPassword = ''
    
    },
    checkPassword(value){
      const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,15}$/
      const reg1 = /^[A-Z]+$/
      if(!reg.test(value) && !reg1.test(value)){
        this.$element.showMsg(this.$t('base.validatePassword'), 'warning')
        return
      }
    },
    confirm(){
      const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,15}$/
      const reg1 = /^[A-Z]+$/
      if(!reg.test(this.oldPassword) && !reg1.test(this.password)){
        return
      }
      if(!reg.test(this.password) && !reg1.test(this.password)){
        return
      }
      if(!reg.test(this.confirmPassword) && !reg1.test(this.password)){
        return
      }

      if (this.password !== this.confirmPassword) {
        this.$element.showMsg(this.$t('personalInfo.inconformity'), 'warning')
        return
      }
      console.log(this.oldPassword,this.password,this.mobile,this.authCodeSeqNo,this.authCode)
      console.log(this.formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.operatorChangePassword,
        data: {
          oldPassword: this.oldPassword,
          password:this.confirmPassword,
          mobile:this.mobile,
          seqNo: this.authCodeSeqNo,
          code:this.authCode
        },
      }).then(() => {
        // lang:密码修改成功，请重新登录
        this.$element
          .showAlert({
            content: this.$t('personalInfo.passwordEditSuc'),
          })
          .then(() => {
            resetRouter()
            // 清除本地缓存数据
            this.$store.dispatch('clearSessionInfo').then(() => {
              this.$router.replace({ path: '/login' })
            })
          })
      })
    },
    mobileHide (value) {
      const start = value.slice(0, 3)
      const end = value.slice(-4)
      return `${start}****${end}`
    },
    onInputCode() {
      this.codeErrshow = false
      this.codeErrText = ""
    },
     // 获取验证码
    sendcode() {
      if(this.formData.mobile){
        this.mobile = this.formData.mobile
      }
      console.log(this.mobile)
      // 验证码
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
        data:{
          mobile: this.mobile,
        }
      }).then((data) => {
        console.log(data)
        this.isSend = false
        this.authCodeSeqNo = data
      })
      const TIME_COUNT = 60   //倒计时60秒
      if(!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if(this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  },
}
</script>
<style lang="scss">
.passBox{
 .el-dialog__header {
    color: #909399;
    font-size: 14px;
    font-weight: bold;
    background: #f5f7fa;
    padding: 10px 15px;
    text-align: left;
    min-height: 21px;
  }
  .el-input__inner{
    color: #000;
  }
}
.fromBox{
  margin-bottom: 20px;
}
.colBox{
  background: #F5F7FA;
  height: 33px;
}
.starBox{
  color: #F77C7C;
  margin-right: 5px;
}
.leftBox{
  float: right;
  margin-top: 5px;
}
.footBox{
  text-align: center;
}

</style>